import * as React from 'react'
import { Link } from 'gatsby'
import { observer } from 'mobx-react-lite'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import AppState from '../appState'
import styled from '@emotion/styled'
import inGermany from '../images/general/made-in-germany.svg'
import silicone from '../images/general/silicone.svg'
import waterproof from '../images/general/waterproof.svg'
import button from '../images/general/go-button.png'
import leftBackground from '../images/general/left-background.png'
import rightBackground from '../images/general/right-background.png'

const Title = styled.h1`
  color: white;
`

const StyledPage = styled(Page)`
  background-image: url(${leftBackground}), url(${rightBackground});
  background-position: left bottom, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 40%, 40%;

  @media screen and (min-width: 1000px) {
    background-size: auto, auto;
  }
`

const StyledLink = styled(Link)`
  color: white;
  font-size: xx-large;
  font-style: italic;

  &:hover {
    text-decoration: none;
    color: white;
  }
`

const StyledButton = styled.div`
  white-space: normal;
  height: unset;
  min-height: 2.25em;
`

const StyledIcon = styled.img`
  width: 50%;
`

const Column = styled.aside``

const VerticalText = styled.div``

const IndexPage = () => {
  const store = React.useContext(AppState)
  const tx = store.translations.landing

  return (
    <IndexLayout>
      <StyledPage>
        <Container>
          <div className="has-text-centered">
            <Title>{tx.title}</Title>
            <p>{tx.blurb}</p>
            <Title>{tx['about:title']}</Title>
            <p>{tx['about:body']}</p>
            <div className="columns is-mobile is-multiline is-centered">
              <Column className="column is-third-mobile is-one-fifth-tablet">
                <StyledIcon src={silicone} />
                <VerticalText>{tx.silicone}</VerticalText>
              </Column>
              <Column className="column is-third-mobile is-one-fifth-tablet">
                <StyledIcon src={inGermany} />
                <VerticalText>{tx.germanEngineered}</VerticalText>
              </Column>
              <Column className="column is-third-mobile is-one-fifth-tablet">
                <StyledIcon src={waterproof} />
                <VerticalText>{tx.waterproof}</VerticalText>
              </Column>
            </div>
            <StyledLink to={store.LinkToQuiz}>
              <StyledButton>{tx.cta}</StyledButton>
              <img src={button} />
            </StyledLink>
          </div>
        </Container>
      </StyledPage>
    </IndexLayout>
  )
}

export default observer(IndexPage)
